.Root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Lead {
  font-size: calc(var(--font-size-medium) * 1.3);
  margin: calc(var(--gutter) * 1.5) calc(var(--gutter) * 4);
}
