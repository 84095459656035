.EmailInput {
  width: 100%;
}

.PasswordInput {
  width: 100%;
}

.ForgotPasswordText {
  color: var(--color-brand);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.03rem;
  text-decoration: none;
}

.BecomeMember {
  text-align: end;
  margin-top: 0.5rem;
}

.BecomeMemberText {
  color: var(--color-brand);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.03rem;
  text-decoration: none;
  font-size: large;
}

.LoadingWrapper {
  margin-top: var(--gutter);
}

.InvalidUser {
  margin-top: var(--gutter);
  text-align: center;
}
.InvalidUserText {
  color: red;
}

/**
 * Non mobile
 */
@media (--viewport-mobile-up) {
  .FormGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .FormGroup .InputWrapper,
  .FormGroup .EmailInput {
    flex-basis: 100%;
    margin-bottom: calc(var(--gutter) * 0.5);
  }

  .FormGroup .InputWrapper:not(:first-child) {
    width: 100%;
  }

  .FormGroup .PasswordWrapper {
    position: relative;
  }

  .FormGroup .ForgotPassword {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    margin-top: calc(var(--gutter) * -1);
    margin-bottom: calc(var(--gutter) * 2.5);
  }

  .FormGroup .BecomeMember {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    margin-top: calc(var(--gutter) * -1);
    margin-bottom: calc(var(--gutter) * 2.5);
  }
}

/**
 * mobile
 */
@media (--viewport-mobile-down) {
  .FormGroup .ForgotPassword {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    margin-top: calc(var(--gutter) * -0.5);
    margin-bottom: calc(var(--gutter) * 2.5);
  }
}
